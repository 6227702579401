import React,{ useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useLang } from '@context/lang';
import {filesize} from "filesize";
import AttachmentsIcon from '../../../../brands/icons/Attachments.svg';
import AttachmentUploader from './AttachmentUploader';
import { AttachmentProvider } from '@context/attachment';
import { formatSize } from '@util/helper';
import './Attachment.scss';
import { useAnalytics } from '../../../../context/analytics';
import { useMessageDraft } from '../../../../context/messageDraft';
import ScreenReaderOnly from '@components/ScreenReaderOnly';

const MB = 1000000;

const acceptSupersetReverseMap = new Map();
acceptSupersetReverseMap.set(['.png'], 'image/png');
acceptSupersetReverseMap.set(['.gif'], 'image/gif');
acceptSupersetReverseMap.set(['.jpeg'], 'image/jpeg');
acceptSupersetReverseMap.set(['.jpg'], 'image/jpg');
acceptSupersetReverseMap.set(['.tiff'], 'image/tiff');
acceptSupersetReverseMap.set(['.txt'], 'text/plain');
acceptSupersetReverseMap.set(['.rtf'], 'application/rtf');
acceptSupersetReverseMap.set(['.doc'], 'application/msword');
acceptSupersetReverseMap.set(['.pdf'], 'application/pdf');
acceptSupersetReverseMap.set( ['.docx'], 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');

/*
Adding mapper to convert file extensions to accept: {} for supporting react-dropzone
Here we are adding superset of mapping and convert mapping as per data supplied by backend 'formats' array

*/
const acceptFilesMapper = (formats= []) => formats.reduce((acc, format) => {
    for(let [key, value] of acceptSupersetReverseMap){
        if(key.includes(format) && acc[value]){
            return {
                ...acc,
                [value]: acc[value].concat(format)
            }
        }
        
        if(key.includes(format) && !acc[value]){
            return {
                ...acc,
                [value]: [format]
            }
        }
    }
    return acc;
    
}, {});
    
function Attachment({field, tabIndex, id, value}) {
    const [acceptedFileList,setAcceptedFileList] = useState([]);
    const [rejectedFileList,setRejectedFileList] = useState([]);
    const maxFileSize = parseInt(field.fileSize) * MB;
    const cumulativeFileMax = field.cumulativeMaxFileSize ? parseInt(field.cumulativeMaxFileSize) * MB : -1;
    const maxFileCount = field.maxFileCount ? parseInt(field.maxFileCount) : 0;
    const { dict } = useLang();
    const { publish } = useAnalytics();
    const { setAttachmentIds } = useMessageDraft();

    useEffect(() => {
        if (typeof value === 'object' && value.attachment) {
            let _acceptedFileList = [];
            value.attachment.forEach(a => {
                uploadHandler(a.attachment_id, a);
                _acceptedFileList.push({uploaded: true, attachmentId: a.attachment_id, name: a.name, size: a.size, mime_type:a.mime_type});
            });
            setAcceptedFileList(_acceptedFileList);
        }
    }, [value])

    function fileSizeValidator(file) {
        if (file.size > maxFileSize) {
            publishAnalyticsForError(dict.errFilesize.analytics);
            return {
                code: "file-too-large",
                message: `${dict.errFilesize.message.replace('{size}', formatSize(maxFileSize))}`
            };
        }
        return null
    }
    
    const publishAnalyticsForError = (errorMessage) => {
        publish(
            { resource: 'fields' },
            {
                type: 'attachment error',
                fields: 'field:attachment',
                name: errorMessage
            }
        )
    }

    const addRejectedList = (fileRejections) => setRejectedFileList(rejectedFileList =>{ return [...rejectedFileList,...fileRejections]});
    
    const {
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: acceptFilesMapper(field.formats),
        validator: fileSizeValidator,
        onDrop: (acceptedFiles, fileRejections) => {
            if (acceptedFiles.length > 0) {
                // if the compose form has a max number of files
                if (maxFileCount > 0) {
                    // move the files that exceed the max count to the rejected files 
                    let rejectedFilesLength = fileRejections.length;
                    while (acceptedFileList.length + acceptedFiles.length > maxFileCount) {
                        const fileItem = acceptedFiles.pop();
                        // analytics and error details
                        publishAnalyticsForError(dict.errFileLimit.analytics);
                        const err = {
                            code: "file-max-count-reached",
                            message: `${dict.errFileLimit.message.replace('{size}', maxFileCount)}`
                        }
                        // add to rejected files
                        fileRejections.push({file: fileItem, errors: [err], idx: rejectedFilesLength++});
                    }
                }
                // if the compose form has a max cumulative file size
                if (cumulativeFileMax > -1) {
                    const currentTotalOfFileSizes = acceptedFileList.reduce((a, c) => a + c.size, 0);
                    const totalSizeOfNewFiles = acceptedFiles.reduce((a, c) => a + c.size, 0);
                    // NOTE: could try to be fancy and allow some files that dont exceed max
                    if (currentTotalOfFileSizes + totalSizeOfNewFiles > cumulativeFileMax) {
                        // move all the accepted files to the file rejections
                        let rejectedFilesLength = fileRejections.length;
                        while (acceptedFiles.length > 0) {
                            const fileItem = acceptedFiles.pop();
                            // analytics and error details
                            publishAnalyticsForError(dict.errCumulativeFilesize.analytics);
                            const err = {
                                code: "cumulative-file-size-reached",
                                message: `${dict.errCumulativeFilesize.message.replace('{size}', formatSize(cumulativeFileMax))}`
                            }
                            // add to rejected files
                            fileRejections.push({file: fileItem, errors: [err], idx: rejectedFilesLength++});
                        }
                    }
                }
            }
            setAcceptedFileList(current => current.concat(acceptedFiles));
            addRejectedList(fileRejections);
        }
    });
    
    const removeAcceptedfile = (e,fileName) => {
        // remove the attachment id if it exists
        const attachmentId = acceptedFileList.find(elem => elem.name === fileName)?.attachmentId;
        if (attachmentId) {
            setAttachmentIds(current => {
                const idIndex = current.findIndex(id => id === attachmentId);
                if (idIndex > -1) {
                    return current.toSpliced(idIndex, 1);
                }
            });
        }
        setAcceptedFileList(acceptedFileList => acceptedFileList.filter(fileItem => fileItem.name !== fileName));
    }
    
    const removeRejectedfile = (e,fileName) => {
        setRejectedFileList(rejectedFileList => rejectedFileList.filter(({file}) => file.name !== fileName));		  
    }

    const geterrorMessage = (errorCode, message) => {
        let errorMessage = message;
        if(errorCode === 'file-invalid-type') {
            errorMessage = dict.errUnsupported.message
        }
        return errorMessage;
    }
    
    const createSrMessage = ({fileSize,formats}) => {
        let message = 'attachmenets, ' + dict.dropFiles.message
        if (fileSize) {
            message += dict.maxFileSize.message.replace('{fileSize}', fileSize)
        }
        if (formats && formats.length > 0) {
            message += dict.fileFormats.message + formats.join(', ')
        }
        return message
    }

    const uploadHandler = (attachmentId, file) => {
        if (attachmentId) {
            file.attachmentId = attachmentId;
            const filename = file.name;
            setAcceptedFileList(current => {
                const fileIndex = current.findIndex(elem => elem.name === filename);
                return current.toSpliced(fileIndex, 1, file);
            });
            setAttachmentIds(current => [...current, attachmentId]);
        }
    }

    return (
        <div className ='oms--attachment'>
            <div className='oms--attachment-hint'>
                <div className='oms--hint-message'>
                    {dict.maxFileSize.message.replace('{fileSize}', field.fileSize)}
                    {field.cumulativeMaxFileSize && <br />}
                    {field.cumulativeMaxFileSize &&
                        dict.cumulativeFileMax.message.replace('{cumulativeFileSize}', field.cumulativeMaxFileSize)
                    }
                    {field.maxFileCount && <br />}
                    {field.maxFileCount &&
                        dict.maxFileCount.message.replace('{fileCount}', field.maxFileCount)
                    }
                </div>
                <div className='oms--hint-message'> {dict.fileFormats.message + field.formats }</div>
            </div>
            {acceptedFileList.length >0 && (
                <div className='oms--attachment-accepted-filelist'>
                    <ul className='oms--accepted-filelist'>
                        { acceptedFileList.map((file,idx) => (
                            <AttachmentProvider key={`accepted_${idx}`}>			
                                <AttachmentUploader 
                                    file={file} 
                                    addRejectedList={addRejectedList}
                                    idx={idx}
                                    tabIndex={tabIndex}
                                    removeAcceptedfile={(e,fileName) => removeAcceptedfile(e,fileName)}
                                    onUploaded={(attachmentId) => uploadHandler(attachmentId, file)} />					  
                            </AttachmentProvider>	
                            ))
                        }
                    </ul>
                </div>
            )}
            {rejectedFileList.length >0 && (
                <div className='oms--attachment-rejected-filelist'>
                    <ul className='oms--rejected-filelist'>
                        {rejectedFileList.map(({file,errors,idx}, index) => 
                            <li className='oms--rejected-filelist-item' key={`rejected_${index}`}>
                                <div className='oms--attachment-rejected-fileinfo'>
                                    <div className='oms--attachment-rejected-fileinfo-icon' role='alert' aria-live='assertive' aria-label={file.name + ' failed to upload. Check the file and try again.'}>
                                        <span className="sm-icon icon--alert_filled" />
                                    </div>
                                    <div className='oms--attachment-rejected-file-details'>
                                        <div className='oms--attachment-rejected-filename'>
                                            {file.name} ({filesize(file.size)})
                                        </div>
                                        <span className='oms--attachment-rejected-error-fileinfo'>{geterrorMessage(errors[0].code, errors[0].message)}</span>
                                    </div>
                                </div>
                                <div className='oms--attachment-rejected-fileaction'>
                                    <button data-testid='dismiss-rejected-button' id={'oms--attachment-rejected-btn-'+idx} className='oms--attachemnt-rejected-fileaction-btn' type='button' onClick={(e) => removeRejectedfile(e,file.name)}>
                                        <div className='oms--attachment-rejected-fileaction-icon'>
                                            <span className="sm-icon icon--remove" />
                                        </div>
                                        <div className='oms--attachment-rejected-fileaction-details'>
                                            {dict.dismissLabel.label} <ScreenReaderOnly message={file.name} />
                                        </div>
                                    </button>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            )}
            <section className='oms--attachment-area'>
                <div {...getRootProps({ className: 'dropzone', "aria-label": createSrMessage({
                    fileSize: field.fileSize,
                    formats: field.formats
                }), role: 'button'})}>
                    <input {...getInputProps()} />
                    <label htmlFor={id} aria-hidden>
                        <div className='oms--attachment-placeholder'>
                            <img
                                src={AttachmentsIcon}
                                alt='attachment'
                                style={{ marginRight: '15px' }}
                            />
                            {dict.dropFiles.message}
                        </div>
                    </label>						
                </div>					  
            </section>
        </div>
    )
}
            
export default Attachment;
            