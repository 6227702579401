import React from 'react';
import Folders from '@components/Folders';
import NoThreads from '@components/Views/NoThreads';
import SplitView from '@components/Views/SplitView';
import SingleView from '@components/Views/SingleView';
import { useView } from '@context/view';
import { MessagesProvider } from '@context/messages';
import { ComposeProvider } from '@context/compose';
import { MailBoxesProvider } from '@context/mailboxes';
import Compose from '@components/Compose/Compose';
import Reply from '@components/Compose/Reply';
import { useConfig } from '@context/config';
import { FieldErrorsProvider } from '@context/fieldErrors';
import { AutoComposeProvider } from '@context/autoCompose';
import { ThreadsProvider, useThreads } from '@context/threads';
import Loader from '@components/Loaders/loader';
import ComposeButton from './ComposeButton';
import Filters from './Filter/Filters';
import { FiltersProvider } from '@context/filter';
import { ThreadFilterProvider } from '../../context/threadFilter';

function ViewManager() {
	const { manager } = useView();
	
	return (
        <div className='oms--message-center'>
            <div className='oms--message-center-bottom-section'>
                <ThreadFilterProvider>
                <ThreadsProvider>
                    <MessagesProvider>
                        <ComposeProvider>
                            <AutoComposeProvider>
                                {
                                    manager === 'browse' ?
                                        <BrowseView />
                                    : manager === 'compose' ?
                                        <FieldErrorsProvider>
                                            <Compose />
                                        </FieldErrorsProvider>
                                    : manager === 'reply' ?
                                        <MailBoxesProvider>
                                            <FieldErrorsProvider>
                                                <Reply />
                                            </FieldErrorsProvider>
                                        </MailBoxesProvider>
                                    : null
                                }
                            </AutoComposeProvider>
                        </ComposeProvider>
                    </MessagesProvider>
                </ThreadsProvider>
                </ThreadFilterProvider>
            </div>
        </div>
	);
}

function BrowseView() {
    const { allowCompose, hideFilter, isMobile, viewManager } = useConfig();
    const { isLoading, threads } = useThreads();
    const { folder } = useView(); 

    return (
        <>
            <Folders />
            <div className='oms--message-center-compose-and-fiters-section'>
                <div className='oms--widget-compose-button'>
                    {allowCompose && <ComposeButton />}
                </div>
                {!hideFilter && folder !== 'drafts' &&
                    <FiltersProvider>
                        <Filters />
                    </FiltersProvider>
                }
            </div>
            <Loader loading={isLoading}>
                {folder !== 'drafts' && threads?.length === 0 ? <NoThreads /> :
                (isMobile || viewManager === 'singleView' || folder === 'drafts') ? <SingleView /> : <SplitView />}
            </Loader>
        </>
    )
}

export default ViewManager;
