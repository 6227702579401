import React,{useEffect, useRef} from 'react';
import {filesize} from "filesize";
import { useAttachment } from '@context/attachment';
import { useMessageDraft } from '@context/messageDraft';
import { useLang } from '../../../../context/lang';
import ScreenReaderOnly from '@components/ScreenReaderOnly';

function AttachmentUploader({ file, idx, removeAcceptedfile, addRejectedList, onUploaded }){
    const { uploadStatus, setUploadStatus, uploadAttachment } = useAttachment();
    const { setIsUploadingAttachments } = useMessageDraft();
    const { dict } = useLang();
    const uploadStatusRef = useRef(null);

    useEffect(() => {
        uploadStatusRef.current.focus();
        if (file.uploaded) {
            setUploadStatus('uploaded');
            return;
        }
        const doUpload = async() => {
            setIsUploadingAttachments(true);
            const attachmentId = await uploadAttachment(file,idx);
            setIsUploadingAttachments(false);
            return attachmentId;
        }

        doUpload().then(attachmentId => {
            onUploaded(attachmentId);
        });
    }, []);
    
    const statusNotUpload = ['uploaded', 'error']
    
    useEffect(() => {
        console.log(uploadStatus);
        if (uploadStatus==='error') {
            removeAcceptedfile({}, file.name)
            addRejectedList([{
                file,
                errors: [{
                    code: 'server',
                    message: dict.errSystem.message
                }],
                idx: file.name
            }])
        }
    }, [uploadStatus])

    return (
        <li className='oms--accepted-filelist-item' key={idx}>
            <div className='oms--attachment-accepted-fileinfo' tabIndex='-1' ref={uploadStatusRef}>
                <div className='oms--attachment-accepted-fileinfo-icon' role='alert' aria-live='assertive' aria-label={file.name + ' ' + uploadStatus}>
                    {uploadStatus == 'uploaded' && <span className="sm-icon icon--checkmark_filled" />}
                    {!statusNotUpload.includes(uploadStatus) && <div className="loader" />}
                </div>
                <div className='oms--attachment-accepted-fileinfo-details'>
                    {file.name} ({filesize(file.size)})
                </div>
            </div>
            <div className='oms--attachment-accepted-fileaction'>
                <button id = {'oms--attachment-accepted-btn-'+idx} className='oms--attachment-accepted-fileaction-btn' type='button' onClick={(e) => removeAcceptedfile(e,file.name)}>
                    <div className='oms--attachment-accepted-fileaction-icon'>
                        <span className="sm-icon icon--trash_delete" />
                    </div>
                    <div className='oms--attachment-accepted-fileaction-details'>
                        {dict.removeAttachment.label} <ScreenReaderOnly message={file.name} />
                    </div>
                </button>
            </div>
        </li>
    )
}
    
export default AttachmentUploader;
    