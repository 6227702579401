import React, { useState, useContext } from 'react';
import { saveAs } from 'file-saver';
import axios from '@util/axios_config';
import { useAnalytics } from './analytics';
import { useLang } from './lang';
import { useErrors } from './errors';
import { formatSize } from '@util/helper';
import { useConfig } from './config';

const AttachmentContext = React.createContext(null);

// eslint-disable-next-line react/function-component-definition
function AttachmentProvider({children}) {
    const [uploadStatus, setUploadStatus] = useState('uploading');
    const { publish } = useAnalytics();
    const { dict } = useLang();
    const { add } = useErrors();
    const { token } = useConfig();

    const uploadAttachment = async (file, idx) => {
        let uploadedAttachmentId = null;
        try {
            file.__cancelTokenSource = axios.CancelToken.source()
            file.__index = idx
            let config = {
                errorScope: 'attachments:upload',
                cancelToken: file.__cancelTokenSource.token,
                options: {}
            };
            if (token) {
                config.headers = {Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data'};
            }
            const data = new FormData()
            data.append(`file_${idx}`, file)
            const response = await axios.post('/attachments', data, config);
            if (response.status === 200) {
                setUploadStatus('uploaded');
                uploadedAttachmentId = response.data.attachment[0].id;
            } else {
                setUploadStatus('error');
                const message = response.status === 422 ? dict.errVirus.message : dict.errSystem.message;
                publish(
                    { resource: 'fields' },
                    {
                        type: 'attachment error',
                        fields: 'field:attachment',
                        name: message
                    }
                )
                add('attachments:upload:failure', `${file.name} (${formatSize(file.size)})|${message}`);
            }
        } catch (e) {
            setUploadStatus('error');
            publish(
                { resource: 'fields' },
                {
                    type: 'attachment error',
                    fields: 'field:attachment',
                    name: dict.errSystem.message
                }
            )
            add('attachments:upload:failure', `${file.name} (${formatSize(file.size)})|${dict.errSystem.message}`);
        }
        return uploadedAttachmentId;
    }

    async function downloadAttachment (
        attachment_id,
        name,
        options = {}
    ) {
        const url = `attachments/${attachment_id}`
        
        try {
            let config = {
                responseType: 'blob', // This option is needed to avoid breaking binary files
                ...options
            };
            if (token) {
                config.headers = {Authorization: `Bearer ${token}`};
            }
            const { data } = await axios.get(url, config)
            
            saveAs(data, name)
        } catch (error) {
            add(
                'attachments:download:failure',
                'Unable to download attachment. Please wait a few minutes and try again.',
                error
            )
            console.error('Attachment failed to download', error)
        }
    }

    const provider = { uploadStatus, setUploadStatus, uploadAttachment, downloadAttachment };
        
    return <AttachmentContext.Provider value={provider}>{children}</AttachmentContext.Provider>
}
        
const useAttachment = () => {
    const context = useContext(AttachmentContext);
    if (!context) {
        throw new Error('useAttachment must be used within a AttachmentContext provider');
    }
    return context;
}


export { useAttachment, AttachmentProvider }
        